
.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
  background-color: transparent;
  border: 0;
}

.nav-pills > li.active > a[data-toggle="tab"],
.nav-pills > li > a[data-toggle="tab"]:hover {
  background: none;
  border: 1px solid $theme-color;
  border-radius: 5px;
  color: $theme-color;
  font-size: 15px;
  font-weight: 600;
}

.nav-pills > li > a[data-toggle="tab"] {
  border: 1px solid $text-color-inactive;
  border-radius: 5px;
  color: $text-color-inactive;
  font-size: 15px;
  font-weight: 600;
  margin-right: 22px;
  padding: 14px 15px;
  margin-bottom: 10px;
}

.nav-pils_custom {

  .navbar-text {
    font-weight: 700;

    @media (max-width: 992px) {
      display: inline-block;
      padding: 0 15px;
    }
  }

  .nav-pills > li {

    @media (max-width: 992px) {
      clear: both;
    }
  }
}
