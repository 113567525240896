.bem_top-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: right;

  @media (max-width: 992px) {
    text-align: left;
  }

  &__item {
    display: inline-block;
    border-left: rgba(0, 0, 0, 0.1) 1px solid;
    border-right: #ebebeb 1px solid;

    &:first-child {
      border-left: 0;
    }

    &:last-child {
      border-right: 0;
    }

    @media (max-width: 992px) {
      border: none;
    }
  }

  &__link {
    color: $top-header-text-color;
    display: inline-block;
    line-height: 40px;
    padding: 0 12px;

    &:hover {
      text-decoration: underline;
    }

    .fa {
      background: url(../img/danabol/top-menu_ico-bg.png) center center no-repeat;
      color: $top-menu-ico-color;
      display: inline-block;
      line-height: 20px;
      width: 20px;
      text-align: center;
    }
  }
}