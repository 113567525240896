.bem_slogan {
  color: $text-color-inactive;
  display: inline-block;
  font-size: 16px;
  margin-top: 43px;

  @media (max-width: 1200px) {
    margin-top: 27px;
    padding-left: 10px;
  }

  @media (max-width: 992px) {
    display: block;
    text-align: center;
  }
}