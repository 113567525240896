.bem_top-nav {
  padding: 0;
  margin: 0;
  list-style: none;

  &__item {
    display: inline-block;
  }

  &__link {
    color: $top-header-text-color;
    display: inline-block;
    line-height: 40px;
    font-size: 13px;
    padding: 0 10px;
    @media (max-width: 400px) {
      padding: 0 4px;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}