.bem_banner {
  position: relative;

  &__headline {
    font-size: 25px;
    font-weight: 400;
  }

  &__link {
    display: block;
    background: rgba(0,0,0,0.5);
    color: $text-color-invert;
    text-decoration: none;
    line-height: 30px;
    transition: width 0.2s;
    text-transform: uppercase;
    font-weight: 700;


    &:hover {
      border-bottom: none;
      color: $text-color-invert;
    }
  }

  &:hover &{

    &__link {
      border-radius: 5px;
      color: $text-color;
      display: inline-block;
      background: $text-color-invert;
      padding: 0 16px;
    }
  }
}