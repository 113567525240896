@import 'fonts';
@import 'vars';
@import 'main';
@import 'bootstrap-custom';
@import 'mystyles';
@import 'style';
@import 'switcher';
@import 'bem_banner';
@import 'bem_top-header';
@import 'bem_top-menu';
@import 'bem_top-nav';
@import 'bem_slogan';
@import 'bem_categories-banners';
@import 'bem_advantage';
@import 'about-zakaz';
@import 'product-advantages';
@import 'bem_headline';
@import 'product-usage';
@import 'usage';
@import 'bem_variants';
@import 'bem_variant';
@import 'bem_text';
@import 'bem_page-text';
@import 'owl-carousel';
@import 'section-header';
@import 'bem_recomended';
@import 'bem_looks';
@import 'bem_button';
@import 'bem_product-info';
@import 'bem_options';
@import 'bem_delivery-info';
@import 'bem_delivery-card';
@import 'bem_delivery-city';
@import 'buyme-custom';
@import 'callme-custom';
@import 'bem_page-top';
@import 'bem_color';
@import 'bem_product-tabs';
@import 'bem_amount';