/*YOUR CUSTOM STYLES*/
.product-header {
  background: #fff;
}

.product_image {
  display: inline-block;
  width: 200px;
  height: 200px;
  line-height: 200px;
}

.product_image img {
  max-width: 200px;
  width: auto;
  max-height: 200px;
  vertical-align: middle;
}

.transfer_class {
  z-index: 10000;
}

.product-title {
  font-family: $font-family;
  min-height: 72px;
}

.comment-inner {
  width: 100%
}

#baloonBody {
  width: auto !important
}

.clear {
  clear: both;
}

.autocomplete-suggestions {
  background-color: #ffffff;
  overflow: hidden;
  border: 1px solid #e0e0e0;
  overflow-y: auto;
}

.autocomplete-suggestions img {
  width: auto;
}

.autocomplete-suggestions .autocomplete-suggestion {
  cursor: default;
}

.autocomplete-suggestions .selected {
  background: #F0F0F0;
}

.autocomplete-suggestions div {
  padding: 2px 5px;
  white-space: nowrap;
}

.autocomplete-suggestions strong {
  font-weight: normal;
  color: #3399FF;
}
