.bem_amount {

  &__headline {
    margin-bottom: 21px;
  }

  &__counter {
    color: $text-color;
    background: $text-color-invert;
    border-radius: 10px;
    display: inline-block;
    vertical-align: middle;
    height: 50px;
    line-height: 50px;
    text-align: center;
    width: 130px;
    margin: 0 5px;
  }

  &__minus-btn {
    width: 50px;
    min-width: 50px;
    height: 50px;
    line-height: 50px;
    padding: 0;
  }

  &__plus-btn {
    width: 50px;
    min-width: 50px;
    height: 50px;
    line-height: 50px;
    padding: 0;
  }
}