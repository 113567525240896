.bem_advantage {

  @media (max-width: 992px) {
    margin-bottom: 15px;
  }

  &__ico {    
    background: #1f1f1f url(../img/danabol/assortiment_ico.png) center center no-repeat;
    width: 89px;
    height: 89px;
    border-radius: 50%;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);

    @media (max-width: 992px) {
      display: inline-block;
    }
    
    &_garantee {
      background-image: url(../img/danabol/garantee_ico.png);
    }
    
    &_geo {
      background-image: url(../img/danabol/geo_ico.png);
    }
    
    &_5-stars {
      background-image: url(../img/danabol/5_stars_ico.png);
    }
  }

  &__left-col {
    display: inline-block;
    width: 35%;
    vertical-align: middle;

    @media (max-width: 1200px) {
      width: 43%;
    }

    @media (max-width: 992px) {
      width: auto;
      display: block;
      text-align: center;
    }
  }

  &__right-col {
    display: inline-block;
    width: 63%;
    vertical-align: middle;

    @media (max-width: 1200px) {
      width: 55%;
    }

    @media (max-width: 992px) {
      width: auto;
      display: block;
      text-align: center;
    }
  }
}