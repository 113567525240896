$font-family: 'Open Sans', Arial, helvetica, sans-serif;
$headers-font-family: 'airborn', Arial, helvetica, sans-serif;
$original-color: #2a8fbd;
$new-color: #518cc7;
$theme-color: $new-color;
$text-light-color: #7c7c7c;
$text-color: #333;
$text-color-invert: #fff;
$background-color: #f1f8ff;
$main-background-color: #fff;
$border-color: #d9d9d9;
$amount-btn-color: #a5a5a5;
$text-color-inactive: #9b9b9b;
$top-header-text-color: #555;
$top-menu-ico-color: #dadada;