#cme-container #cme-form-main.cme-tpl-default .h6 {
  background: $theme-color !important;
}

#cme-container #cme-form-main.cme-tpl-default #callme-submit {
  background: $theme-color !important;
  box-shadow: 0 3px 0 darken($theme-color, 10%) !important;
}

#cme-container #cme-form-main.cme-tpl-default #callme-close {
  background: $text-color-invert !important;
  color: $text-color !important;
}