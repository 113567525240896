.bem_categories-banners{
  text-align: center;

  &__item {
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    width: 223px;
    margin-right: 2px;

    @media (max-width: 1200px) {
      width: 183px;
    }

    @media (max-width: 992px) {
      width: auto;
      margin-bottom: 4px;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}