.usage {
  margin-top: 50px;

  &__headline {
    margin: 0;
  }

  &__header {
    position: relative;
  }

  &__overlay {
    background: -moz-linear-gradient(45deg, $theme-color 20, rgba(0,0,0,0.5) 81%); /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, $theme-color 20, rgba(0,0,0,0.5) 81%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, $theme-color 0%, rgba(0,0,0,0.5) 81%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: opacity .2s;
  }

  &:hover & {

    &__overlay {
      opacity: 1;
    }
    
    &__footer {
      background: $theme-color;
    }
  }

  &__footer {
    background: $text-color;
    padding: 20px 15px;
    transition: background .2s;

    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }

  &__link {
    color: $text-color-invert;
    text-transform: uppercase;
    line-height: 27px;
    font-size: 13px;

    &:hover {
      color: $text-color-invert;
      text-decoration: underline;
    }
  }

  &_margin-left_10 {
    margin-left: 10px;
  }

  &_margin-left_13 {
    margin-left: 13px;
  }

  &_margin-right_13 {
    margin-right: 13px;
  }
}