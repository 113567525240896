.bem_product-info {

  &__headline {
    margin: 0;
    margin-top: 28px;
    margin-bottom: 18px;
  }

  &__price {
    color: $text-color;
    font-family: $headers-font-family;
    font-weight: 700;
    font-size: 50px;
    line-height: 1em;
    margin-bottom: 25px;
  }

  &__features {
    margin-bottom: 50px;
    padding: 0;
    list-style: none;
  }

  &__colors {
    margin-bottom: 10px;
  }

  &__descr {
    margin-top: 40px;
    max-width: 526px;

    p {
      font-size: 18px;
    }
  }


}