/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 19, 2017 */

@font-face {
  font-family: 'airborn';
  src: url('../fonts/airborne.eot');
  src: url('../fonts/airborne.eot?#iefix') format('embedded-opentype'),
  url('../fonts/airborne.woff2') format('woff2'),
  url('../fonts/airborne.woff') format('woff'),
  url('../fonts/airborne.ttf') format('truetype'),
  url('../fonts/airborne.svg#airborneregular') format('svg');
  font-weight: normal;
  font-style: normal;

}

/*@font-face {
    font-family: 'Bebas Neue';
    src: url('../fonts/bebasneue_bold_0.eot');
    src: url('../fonts/bebasneue_bold_0.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bebasneue_bold_0.woff2') format('woff2'),
         url('../fonts/bebasneue_bold_0.woff') format('woff'),
         url('../fonts/bebasneue_bold_0.ttf') format('truetype'),
         url('../fonts/bebasneue_bold_0.svg#bebas_neuebold') format('svg');
    font-weight: 700;
    font-style: normal;

}




@font-face {
    font-family: 'Bebas Neue';
    src: url('../fonts/bebasneue_book_0.eot');
    src: url('../fonts/bebasneue_book_0.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bebasneue_book_0.woff2') format('woff2'),
         url('../fonts/bebasneue_book_0.woff') format('woff'),
         url('../fonts/bebasneue_book_0.ttf') format('tr../fonts/uetype'),
         url('../fonts/bebasneue_book_0.svg#bebas_neuebook') format('svg');
    font-weight: 400;
    font-style: normal;

}




@font-face {
    font-family: 'Bebas Neue';
    src: url('../fonts/bebasneue_light_0.eot');
    src: url('../fonts/bebasneue_light_0.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bebasneue_light_0.woff2') format('woff2'),
         url('../fonts/bebasneue_light_0.woff') format('woff'),
         url('../fonts/bebasneue_light_0.ttf') format('truetype'),
         url('../fonts/bebasneue_light_0.svg#bebas_neuelight') format('svg');
    font-weight: 300;
    font-style: normal;

}




@font-face {
    font-family: 'Bebas Neue';
    src: url('../fonts/bebasneue_regular_0.eot');
    src: url('../fonts/bebasneue_regular_0.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bebasneue_regular_0.woff2') format('woff2'),
         url('../fonts/bebasneue_regular_0.woff') format('woff'),
         url('../fonts/bebasneue_regular_0.ttf') format('truetype'),
         url('../fonts/bebasneue_regular_0.svg#bebas_neue_regularregular') format('svg');
    font-weight: 400;
    font-style: normal;

}




@font-face {
    font-family: 'Bebas Neue';
    src: url('../fonts/bebasneue_thin_0.eot');
    src: url('../fonts/bebasneue_thin_0.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bebasneue_thin_0.woff2') format('woff2'),
         url('../fonts/bebasneue_thin_0.woff') format('woff'),
         url('../fonts/bebasneue_thin_0.ttf') format('truetype'),
         url('../fonts/bebasneue_thin_0.svg#bebas_neuethin') format('svg');
    font-weight: 100;
    font-style: normal;

}*/



/*

@font-face {
    font-family: 'pt_sansbold';
    src: url('../fonts/pt_sans-web-bold.eot');
    src: url('../fonts/pt_sans-web-bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/pt_sans-web-bold.woff2') format('woff2'),
         url('../fonts/pt_sans-web-bold.woff') format('woff'),
         url('../fonts/pt_sans-web-bold.ttf') format('truetype'),
         url('../fonts/pt_sans-web-bold.svg#pt_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'pt_sansbold_italic';
    src: url('../fonts/pt_sans-web-bolditalic.eot');
    src: url('../fonts/pt_sans-web-bolditalic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/pt_sans-web-bolditalic.woff2') format('woff2'),
         url('../fonts/pt_sans-web-bolditalic.woff') format('woff'),
         url('../fonts/pt_sans-web-bolditalic.ttf') format('truetype'),
         url('../fonts/pt_sans-web-bolditalic.svg#pt_sansbold_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'pt_sansitalic';
    src: url('../fonts/pt_sans-web-italic.eot');
    src: url('../fonts/pt_sans-web-italic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/pt_sans-web-italic.woff2') format('woff2'),
         url('../fonts/pt_sans-web-italic.woff') format('woff'),
         url('../fonts/pt_sans-web-italic.ttf') format('truetype'),
         url('../fonts/pt_sans-web-italic.svg#pt_sansitalic') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'pt_sansregular';
    src: url('../fonts/pt_sans-web-regular.eot');
    src: url('../fonts/pt_sans-web-regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/pt_sans-web-regular.woff2') format('woff2'),
         url('../fonts/pt_sans-web-regular.woff') format('woff'),
         url('../fonts/pt_sans-web-regular.ttf') format('truetype'),
         url('../fonts/pt_sans-web-regular.svg#pt_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;

}*/
